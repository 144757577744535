



















import { ProfileModel } from '@/models/profile-model'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { SummaryViewModel } from '../../viewmodels/summary-viewmodel'

@Observer
@Component({
  components: {
    avatar: () => import('@/modules/community/common/components/avatar.vue'),
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
  },
})
export default class Like extends Vue {
  @Inject() vm!: SummaryViewModel
  @Prop() profile?: ProfileModel
  walletStore = walletStore
}
